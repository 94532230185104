<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="4">
        <v-card class="pa-2" outlined>
          <v-col cols="12">
            <v-card-title>{{ nodeDetailPageText }}</v-card-title>
          </v-col>
          <v-col cols="12">
            <v-progress-linear
              :value="trueToTotalRatio(this.cleaningData)"
              rounded
              height="10"
            ></v-progress-linear>
          </v-col>
          <!-- <v-img
            lazy-src="../assets/Room.jpeg"
            src="../assets/Room.jpeg"
            fill-height
            class="rounded-0"
          ></v-img> -->
        </v-card>
      </v-col>
      <v-col cols="12" lg="8">
        <v-card class="pa-2" outlined tile>
          <v-card-title>Cleaning</v-card-title>
          <v-container fluid>
            <v-row>
              <v-col
                v-for="(group, key) of cleaningCheckboxSkeleton"
                :key="key"
                cols="6"
                md="4"
              >
                <v-checkbox
                  @click="
                    bulkUpdateCleaningState(
                      group,
                      cleaningCheckboxGroupState[key]
                    )
                  "
                  :indeterminate="cleaningCheckboxGroupState[key] === 2"
                  v-model="cleaningCheckboxGroupState[key]"
                  :key="key"
                  ><template v-slot:label
                    ><div>{{ key }}</div></template
                  >
                </v-checkbox>
                <v-checkbox
                  @click="updateCleaningState()"
                  v-for="element in group"
                  v-model="cleaningData.clean[element.id]"
                  :key="element.id"
                  class="ml-3 my-0 py-0"
                >
                  <template v-slot:label
                    ><div>{{ element.name }}</div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-card class="mx-auto">
          <v-card-title>
            Maintenance Records
            <v-spacer></v-spacer>
            <v-btn
              v-if="this.$store.state.userIsCoreMember"
              @click="
                showResolvedMaintenanceRecords = !showResolvedMaintenanceRecords
              "
            >
              {{
                showResolvedMaintenanceRecords
                  ? "Hide Resolved"
                  : "Show Resolved"
              }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-list class="mb-4">
                  <v-list-item
                    v-for="item in maintenanceRecordsFiltered"
                    :key="item.key"
                  >
                    <v-col cols="9">
                      <v-row>
                        <v-col cols="12">
                          <v-list-item-title>
                            {{ item.title }}
                          </v-list-item-title>
                          <v-list-item-content>
                            {{ item.description }}
                            <br />
                            <span class="green--text">
                              {{ formatDate(item.timestamp.seconds) }}
                            </span>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="3">
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-chip
                          v-if="item.resolved"
                          class="white--text"
                          color="green"
                          @click="toggleResolvedMaintenance(item.id, false)"
                        >
                          Resolved
                        </v-chip>
                        <v-chip
                          v-else
                          class="white--text"
                          color="orange"
                          @click="toggleResolvedMaintenance(item.id, true)"
                        >
                          Unresolved
                        </v-chip>
                      </v-row>
                    </v-col>
                  </v-list-item>
                  <v-btn
                    elevation="2"
                    fab
                    absolute
                    bottom
                    right
                    dark
                    color="green"
                    @click="newMaintenanceRecordOpen = true"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-expand-transition>
            <div v-show="newMaintenanceRecordOpen" class="pb-5">
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="maintenanceRecordTitle"
                      label="Title of Issue"
                      :rules="maintenanceTitleRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="maintenanceRecordDescription"
                      label="Please Describe the Issue"
                      auto-grow
                      rows="1"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="newMaintenanceRecordOpen = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    class="float-right"
                    color="blue"
                    elevation="1"
                    dark
                    @click="newMaintenanceRecord()"
                  >
                    Save Record
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto">
          <v-card-title>
            Lost and Found
            <v-spacer></v-spacer>
            <v-btn
              v-if="this.$store.state.userIsCoreMember"
              @click="
                showResolvedLostAndFoundRecords =
                  !showResolvedLostAndFoundRecords
              "
            >
              {{
                showResolvedLostAndFoundRecords
                  ? "Hide Resolved"
                  : "Show Resolved"
              }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-list class="mb-4">
                  <v-list-item
                    v-for="item in lostAndFoundRecordsFiltered"
                    :key="item.key"
                  >
                    <v-col cols="9">
                      <v-row>
                        <v-col cols="12">
                          <v-list-item-title>
                            Item ID: {{ item.id }}
                          </v-list-item-title>
                          <v-list-item-content>
                            {{ item.description }}
                            <br />
                            <span class="green--text">
                              {{ formatDate(item.timestamp.seconds) }}
                            </span>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="3">
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-chip
                          v-if="item.resolved"
                          class="white--text"
                          color="green"
                          @click="toggleResolvedLostAndFound(item.id, false)"
                        >
                          Resolved
                        </v-chip>
                        <v-chip
                          v-else
                          class="white--text"
                          color="orange"
                          @click="toggleResolvedLostAndFound(item.id, true)"
                        >
                          Unresolved
                        </v-chip>
                      </v-row>
                    </v-col>
                  </v-list-item>
                  <v-btn
                    elevation="2"
                    fab
                    absolute
                    bottom
                    right
                    dark
                    color="green"
                    @click="newLostAndFoundRecordOpen = true"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-expand-transition>
            <div v-show="newLostAndFoundRecordOpen" class="pb-5">
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="lostAndFoundItemDescription"
                      label="Please Describe the Item"
                      auto-grow
                      rows="1"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="newLostAndFoundRecordOpen = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    class="float-right"
                    color="blue"
                    elevation="1"
                    dark
                    @click="newLostAndFoundRecord()"
                  >
                    Save Record
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { firebaseApp, Timestamp } from "../firebase/init";
import "firebase/firestore";

export default {
  name: "BuildingsDetail",
  data() {
    return {
      task: null,
      newMaintenanceRecordOpen: false,
      maintenanceRecordTitle: "",
      maintenanceRecordDescription: "",
      maintenanceTitleRules: [
        (v) => !!v || "Title is required",
        (v) => v.length <= 20 || "Title must be less than 20 characters",
      ],
      maintenanceRecords: [],
      showResolvedMaintenanceRecords: false,
      newLostAndFoundRecordOpen: false,
      lostAndFoundId: 9999999,
      lostAndFoundItemDescription: "",
      lostAndFoundRecords: [],
      showResolvedLostAndFoundRecords: false,
      cleaningData: {
        clean: { 601: false, 602: false, 603: false, 604: false },
      },
      roomCleaningData: {},
      noRecordAtId: false,
    };
  },
  // *----------------------- C o m p u t e d ---------------------------------------------------
  computed: {
    maintenanceRecordsFiltered() {
      if (this.showResolvedMaintenanceRecords) return this.maintenanceRecords;
      return this.maintenanceRecords.filter((record) => !record.resolved);
    },
    lostAndFoundRecordsFiltered() {
      if (this.showResolvedLostAndFoundRecords) return this.lostAndFoundRecords;
      return this.lostAndFoundRecords.filter((record) => !record.resolved);
    },
    detailPage() {
      return this.$store.state.buildingDetailPage;
    },
    id() {
      console.log(Object.keys(this.$route.params).length);
      if ("id" in this.$route.params) {
        console.log(`Building Details Id: ${this.$route.params.id}`);
        return this.$route.params.id;
      }
      console.log("Building Details Id: 0.1.0.17");
      return "0.1.0.17";
    },
    nodeName() {
      return this.$route.params.nodeName
        ? this.$route.params.nodeName
        : "lobby";
    },
    nodeDetailPageText() {
      return this.$route.params.nodeDetailText
        ? this.$route.params.nodeDetailText
        : "Lobby";
    },
    items() {
      const roomCleaningItems = require("../json/cleaningItems.json");
      return roomCleaningItems;
    },
    cleaningCheckboxSkeleton() {
      let roomTypes = Object.keys(this.items);
      let roomTypeKey = null;
      var output = new Object();
      for (let roomType of roomTypes)
        if (this.nodeName.includes(roomType)) roomTypeKey = roomType;
      if (this.items[roomTypeKey][0].children[0].children !== undefined) {
        for (let child of this.items[roomTypeKey][0].children) {
          let columnTitle = child.name;
          output[columnTitle] = [];
          for (let item of child.children) {
            output[columnTitle].push(item);
          }
        }
        return output;
      } else {
        let columnTitle = this.items[roomTypeKey][0].name;
        output[columnTitle] = [];
        for (let child of this.items[roomTypeKey][0].children) {
          output[columnTitle].push(child);
        }
        return output;
      }
    },
    cleaningCheckboxGroupState() {
      let output = {};
      if (
        this.cleaningData &&
        "clean" in this.cleaningData &&
        Object.keys(this.cleaningData.clean).length !== 0
      ) {
        for (let group in this.cleaningCheckboxSkeleton) {
          let falseValues = false;
          let trueValues = false;
          for (let element of this.cleaningCheckboxSkeleton[group]) {
            if (this.cleaningData.clean[element.id]) {
              trueValues = true;
            } else {
              falseValues = true;
            }
          }
          if (!falseValues) {
            output[group] = 1;
          } else if (!trueValues) {
            output[group] = 0;
          } else {
            output[group] = 2;
          }
        }
        return output;
      }
      return output;
    },
  },
  // *----------------------- L i f e   c i r c l e ---------------------------------------------
  created() {},
  // *----------------------- M e t h o d s -----------------------------------------------------
  methods: {
    updateCleaningState() {
      if (this.noRecordAtId) {
        firebaseApp.firestore().collection("rooms").doc(this.id).set({
          clean: this.cleaningData.clean,
          id: this.id,
          name: this.nodeName,
        });
      } else {
        firebaseApp.firestore().collection("rooms").doc(this.id).update({
          clean: this.cleaningData.clean,
        });
      }
    },
    bulkUpdateCleaningState(group, state) {
      for (let element of group) {
        this.cleaningData.clean[element.id] = state;
      }
      if (this.noRecordAtId) {
        firebaseApp.firestore().collection("rooms").doc(this.id).set({
          clean: this.cleaningData.clean,
          id: this.id,
          name: this.nodeName,
        });
      } else {
        firebaseApp.firestore().collection("rooms").doc(this.id).update({
          clean: this.cleaningData.clean,
        });
      }
    },
    trueToTotalRatio(object) {
      if (object) {
        let falseValue = 0;
        let trueValue = 0;
        for (let item in object.clean) {
          if (object.clean[item]) {
            trueValue++;
          } else {
            falseValue++;
          }
        }
        return (trueValue / (trueValue + falseValue)) * 100;
      }
      return 0;
    },
    newMaintenanceRecord() {
      firebaseApp
        .firestore()
        .collection("maintenance-records")
        .add({
          id: this.id,
          resolved: false,
          title: this.maintenanceRecordTitle,
          description: this.maintenanceRecordDescription,
          timestamp: Timestamp.fromDate(new Date()),
        });
      this.newMaintenanceRecordOpen = false;
      this.maintenanceRecordDescription = "";
      this.maintenanceRecordTitle = "";
    },
    newLostAndFoundRecord() {
      firebaseApp
        .firestore()
        .collection("lost-and-found")
        .doc((parseInt(this.lostAndFoundId[0].id) + 1).toString())
        .set({
          id: parseInt(this.lostAndFoundId[0].id) + 1,
          roomId: this.id,
          resolved: false,
          description: this.lostAndFoundItemDescription,
          timestamp: Timestamp.fromDate(new Date()),
        });
      this.newLostAndFoundRecordOpen = false;
      this.lostAndFoundItemDescription = "";
    },
    toggleResolvedLostAndFound(id, newState) {
      if (this.$store.state.userIsCoreMember) {
        firebaseApp.firestore().collection("lost-and-found").doc(id).update({
          resolved: newState,
        });
      }
    },
    toggleResolvedMaintenance(id, newState) {
      if (this.$store.state.userIsCoreMember) {
        firebaseApp
          .firestore()
          .collection("maintenance-records")
          .doc(id)
          .update({
            resolved: newState,
          });
      }
    },
    formatDate(date) {
      return moment(date * 1000).format("lll");
    },
  },
  // *----------------------- W a t c h ---------------------------------------------------------
  watch: {
    id: {
      immediate: true,
      handler(id) {
        this.$bind(
          "cleaningData",
          firebaseApp.firestore().collection("rooms").doc(id)
        );
        this.$bind(
          "maintenanceRecords",
          firebaseApp
            .firestore()
            .collection("maintenance-records")
            .where("id", "==", id)
            .orderBy("timestamp")
        );
        this.$bind(
          "lostAndFoundRecords",
          firebaseApp
            .firestore()
            .collection("lost-and-found")
            .where("roomId", "==", id)
            .orderBy("timestamp")
        );
        this.$bind(
          "lostAndFoundId",
          firebaseApp
            .firestore()
            .collection("lost-and-found")
            .orderBy("id", "desc")
            .limit(1)
        );
      },
    },
  },
  firestore: {
    roomCleaningData: firebaseApp.firestore().collection("rooms"),
  },
};
</script>
